<template>
  <div class="marks">
    <div class="headline">
      <h1 class="title">
        Oznake
      </h1>
      <div class="action">
        <Button class="btn-primary" @click.prevent="isCreateDialogVisible = true">
          <i class="el-icon-plus"></i>
        </Button>
      </div>
    </div>
    <div class="items" v-if="isDataPrepared">
      <div class="item" v-for="item in items" :key="item.id">
        <div class="name">
          {{ item.content.label }} · #{{ item.content.increment }}
        </div>
        <div class="action">
          <Button class="btn-light" @click="updateItem(item)">
            <i class="el-icon-edit"></i>
          </Button>
          <Button class="btn-light" @click="removeItem(item)">
            <i class="el-icon-close"></i>
          </Button>
        </div>
      </div>
    </div>
    <AdministrationMarksRootModalsCreate
      :isCreateDialogVisible="isCreateDialogVisible"
      @getItems="getItems"
      @closeDialog="isCreateDialogVisible = false"/>
    <AdministrationMarksRootModalsUpdate
      :isUpdateDialogVisible="isUpdateDialogVisible"
      :operatingItem="operatingItem"
      @getItems="getItems"
      @closeDialog="isUpdateDialogVisible = false"/>
    <AdministrationMarksRootModalsRemove
      :isRemoveDialogVisible="isRemoveDialogVisible"
      :operatingItem="operatingItem"
      @getItems="getItems"
      @closeDialog="isRemoveDialogVisible = false"/>
  </div>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Components
import AdministrationMarksRootModalsCreate from '@/views/Administration/Marks/Root/Modals/Create';
import AdministrationMarksRootModalsUpdate from '@/views/Administration/Marks/Root/Modals/Update';
import AdministrationMarksRootModalsRemove from '@/views/Administration/Marks/Root/Modals/Remove';

// Element UI
import {Button} from 'element-ui';

// Logic
export default {
  name: 'AdministrationMarksRootIndex',
  mixins: [Common],
  components: {
    AdministrationMarksRootModalsCreate,
    AdministrationMarksRootModalsUpdate,
    AdministrationMarksRootModalsRemove,
    Button
  },
  data() {
    return {
      operatingItem: {
        id: '',
        content: {
          label: '',
          increment: 0,
        }
      },
      items: [],
      isCreateDialogVisible: false,
      isUpdateDialogVisible: false,
      isRemoveDialogVisible: false
    }
  },
  methods: {
    getItems() {
      this.setLoadingComponent(true);
      this.Axios.get('administration/mark').then(response => {
        if (response.data.content.data) {
          this.items = response.data.content.data;
        } else {
          this.items = [];
        }
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
          this.items = [];
        }
      }).finally(() => {
        this.setLoadingComponent(false);
      });
    },
    updateItem(item) {
      this.operatingItem = item;
      this.isUpdateDialogVisible = true;
    },
    removeItem(item) {
      this.operatingItem = item;
      this.isRemoveDialogVisible = true;
    }
  },
  created() {
    this.initialize();
    this.getItems();
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';
@import '@/assets/scss/elements/_buttons.scss';

.marks {
  .items {
    margin-top: 32px;
    margin-bottom: 48px;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $grey-100;
      padding-bottom: 8px;
      margin-bottom: 8px;

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .action {
        .el-button {
          width: 48px;
          min-width: unset;
          height: 48px;
        }
      }
    }
  }
}
</style>
