<template>
  <Dialog title="Nova oznaka" :visible="isCreateDialogVisible" :show-close="false">
    <div class="form">
      <Form :model="formData" :rules="rules" ref="componentForm" @submit.native.prevent="validateForm('componentForm')">
        <FormItem prop="item.content.label" label="Oznaka">
          <Input
              v-model="formData.item.content.label"/>
        </FormItem>
        <FormItem prop="item.content.increment" label="Godina">
          <InputNumber
              controls-position="right"
              v-model="formData.item.content.year"/>
        </FormItem>
        <FormItem prop="item.content.increment" label="Inkrement">
          <InputNumber
              controls-position="right"
              v-model="formData.item.content.increment"/>
        </FormItem>
        <FormItem>
          <Button
              class="btn-grey"
              icon="el-icon-close"
              @click="$emit('closeDialog')"/>
          <Button
              native-type="submit"
              class="btn-light"
              icon="el-icon-check"
              :loading="loading"/>
        </FormItem>
      </Form>
    </div>
  </Dialog>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Element UI
import {Form, FormItem, Input, InputNumber, Button, Dialog} from 'element-ui';

// Logic
export default {
  name: 'AdministrationMarksRootModalsCreate',
  mixins: [Common],
  components: {
    Form,
    FormItem,
    Input,
    InputNumber,
    Button,
    Dialog
  },
  props: [
    'isCreateDialogVisible'
  ],
  data() {
    return {
      formData: {
        item: {
          content: {
            label: '',
            year: 2021,
            increment: 0
          }
        }
      },
      rules: {
        item: {
          content: {
            label: [
              {
                required: true,
                message: 'Molimo ispunite polje',
                trigger: ['blur']
              }
            ],
            year: [
              {
                required: true,
                message: 'Molimo ispunite polje',
                trigger: ['blur']
              }
            ],
            increment: [
              {
                required: true,
                message: 'Molimo ispunite polje',
                trigger: ['blur']
              }
            ],
          }
        }
      }
    }
  },
  methods: {
    validateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitForm(formName);
        } else {
          return false;
        }
      });
    },
    submitForm(formName) {
      this.setLoadingComponent(true);
      this.Axios.post('administration/mark/create', {
        content: JSON.stringify(this.formData.item.content)
      }).then(response => {
        this.message = response.data.content.message;
        this.status = response.data.content.status;
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
        }
      }).finally(() => {
        this.setLoadingComponent(false);
        this.setNotification();
        this.setData(formName);
        this.$emit('getItems');
        this.$emit('closeDialog');
      });
    },
    setData(formName) {
      this.formData = {
        item: {
          content: {
            label: '',
            year: 2021,
            increment: 0
          }
        }
      };
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';
@import '@/assets/scss/elements/_buttons.scss';
</style>

